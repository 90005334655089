<template>
  <v-container id="conductores" fluid tag="section">
    <v-card>
      <v-card-text>
        <v-card outlined>
          <v-card-subtitle>
            <v-btn
              color="primary"
              @click="showPanel = !showPanel"
              text
              :title="
                !showPanel
                  ? 'Abrir panel de Consulta de ' + title
                  : 'Cerrar panel de Consulta de ' + title
              "
              class="text-left"
            >
              Consulta de {{ title }}
            </v-btn>
          </v-card-subtitle>
          <v-expand-transition>
            <v-card-text v-show="showPanel">
              <v-form ref="formBusqueda" v-model="valid" lazy-validation>
                <!--
								<v-row dense>
									<v-col cols="12" md="6" sm="12">

									</v-col>
									<v-col cols="12" md="6" sm="12">
										<v-menu
											ref="menuFecha"
											v-model="menuFecha"
											:close-on-content-click="false"
											:return-value.sync="date"
											transition="scale-transition"
											offset-y
											min-width="auto"
										>
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="dateRangeText"
													label="Fecha Pedido"
													readonly
													v-bind="attrs"
													v-on="on"
												></v-text-field>
											</template>
											<v-date-picker v-model="dates" range no-title scrollable>
												<v-spacer></v-spacer>
												<v-btn text color="primary" @click="menuFecha = false">
													Cancelar
												</v-btn>
												<v-btn
													text
													color="primary"
													@click="$refs.menuFecha.save(date)"
												>
													OK
												</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
                -->
                <v-row>
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.patente"
                      label="Patente"
                    ></v-text-field>
                  </v-col>
                  <!--
                                    <v-col cols="12" md="3" sm="12">
                                        <v-text-field
                                            v-model="formSearch.numero"
                                            label="Numero"
                                        ></v-text-field>
                                    </v-col>
                                    -->
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.anio"
                      label="Año"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-text-field
                      v-model="formSearch.chasis"
                      label="Chasis"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="marcas"
                      item-value="id"
                      item-text="nombre"
                      label="Marca"
                      v-model="formSearch.marca_id"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="tipos"
                      item-value="id"
                      item-text="nombre"
                      label="Tipo de Vehiculo"
                      v-model="formSearch.tipo_vehiculo_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="deptos"
                      item-value="id"
                      item-text="nombre"
                      label="Departamento"
                      v-model="formSearch.depto_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="estados"
                      item-value="id"
                      item-text="nombre"
                      label="Estados"
                      v-model="formSearch.estado_id"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="12">
                    <v-autocomplete
                      :items="combustibles"
                      item-value="id"
                      item-text="nombre"
                      label="Combustibles"
                      v-model="formSearch.combustible_id"
                    ></v-autocomplete>
                  </v-col>
                  <!--
                                    <v-col cols="12" md="3" sm="12">
                                        <v-autocomplete
                                            :items="versiones"
                                            item-value="id"
                                            item-text="nombre"
                                            label="Versiones"
                                            v-model="formSearch.version_id"
                                        ></v-autocomplete>
                                    </v-col>
                                    -->
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="12" sm="12" justify="end" align="right">
                    <v-btn
                      color="primary"
                      small
                      @click="
                        page = 1;
                        initialize();
                      "
                    >
                      Buscar
                    </v-btn>
                    <v-btn color="red" small @click="reset"> Reset </v-btn>
                  </v-col>
                </v-row>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col></v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-expand-transition>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="loading"
          class="elevation-1"
          :options.sync="pagination"
          :server-items-length="total"
          :footer-props="footerProps"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Lista de {{ title }}</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="100%">
                <template v-if="visible" v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mx-2"
                    fab
                    v-bind="attrs"
                    v-on="on"
                    ><v-icon blue>mdi-plus</v-icon></v-btn
                  >
                </template>
                <v-card>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-toolbar dark color="primary" class="flex-grow-0">
                      <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-toolbar-items>
                        <v-btn dark text @click="save()" :disabled="!valid"
                          >Guardar</v-btn
                        >
                      </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text style="overflow-y: scroll; max-height: 92vh">
                      <vehiculo-form-component
                        :key="componentKey"
                        @send-message="close(editedItem)"
                        ref="vehiculoForm"
                      ></vehiculo-form-component>
                    </v-card-text>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-spacer></v-spacer>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-row class="">
              <v-col cols="12" md="12" sm="12" style="padding: 6px 0px 0px 0px">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="transparent elevation-0"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                      min-width="30px"
                    >
                      <v-icon
                        large
                        color="blue-grey lighten-3"
                        style="font-size: 30px"
                      >
                        mdi-dialpad
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="editItem(item)">
                      <v-list-item-icon class="mr-0"
                        ><v-icon
                          small
                          class="mr-2"
                          color="blue"
                          title="Editar Vehiculo"
                        >
                          mdi-pencil
                        </v-icon> </v-list-item-icon
                      ><v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <!--
                                        <v-list-item
                                            @click="editItem(item, true)"
                                        >
                                            <v-list-item-icon class="mr-0"
                                                ><v-icon
                                                    small
                                                    class="mr-2"
                                                    color="black"
                                                    title="Ver Vehiculo"
                                                >
                                                    mdi-eye-arrow-right-outline
                                                </v-icon> </v-list-item-icon
                                            ><v-list-item-content>
                                                <v-list-item-title
                                                    >Ver
                                                    Vehiculo</v-list-item-title
                                                >
                                            </v-list-item-content>
                                        </v-list-item>
										-->
                    <v-list-item @click="$deleteItem(item.id, item.patente)">
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          color="orange"
                          title="Eliminar"
                        >
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
      <v-row>
        <v-col cols="12" md="12" sm="12" class="text-right">
          <v-btn color="success">
            <download-excel
              :fields="json_fields"
              :fetch="fetchData"
              :type="dataExportType"
              :name="
                'export-' + title + '-' + this.getDate() + '.' + dataExportType
              "
            >
              Exportar Excel
            </download-excel>
          </v-btn>
        </v-col>
      </v-row>
      <v-dialog v-model="dialogDetails" fullscreen scrollable persistent>
        <vehiculo-details-component
          :key="componentKeyDetails"
          v-bind:dataVehiculo="editedItem"
          @send-message="close(editedItem)"
        ></vehiculo-details-component>
      </v-dialog>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <vue-confirm-dialog></vue-confirm-dialog>
  </v-container>
</template>

<script>
function title() {
  return "Vehiculos";
}

export default {
  data: (vm) => ({
    componentKey: 0,
    componentKeyDetails: 0,
    tipos: [],
    marcas: [],
    zonas: [],
    deptos: [],
    estados: [],
    versiones: [],
    combustibles: [],
    dates: [],
    formSearch: {},
    pagination: {},
    total: 0,
    loading: true,
    footerProps: { "items-per-page-options": [5, 10, 15, 30] },
    menuFecha: false,
    showPanel: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    valid: true,
    _method: "PUT",
    autoGrow: true,
    rows: 1,
    title: title(),
    route: "vehiculos",
    urlExport: "vehiculos_export",
    dataExportType: "xls",
    json_fields: {
      patente: "patente",
      /*numero: "numero",*/
      Año: "anio",
      marca: "marca",
      modelo: "modelo",
      tipo_vehiculo: "tipo_vehiculo",
      combustible: "combustible",
      chasis: "chasis",
      motor: "motor",
      color: "color",
      autopartes: "autopartes",
      fecha_adquisicion: "fecha_adquisicion",
      gps: "gps",
      /*version: "version",*/
      zonas: "zona",
      talleres: "taller",
      departamento: "departamento",
      observaciones: "observaciones",
      estado: "estado",
    },
    menu: false,
    modal: false,
    dialog: false,
    dialogDetails: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    rules: [(v) => v.length <= 100 || "Max 100 caracteres"],
    search: "",
    rulesRequired: [(v) => !!v || "Requerido"],
    users: false,
    headers: [
      /*{ text: "Numero", filterable: true, value: "numero" },*/
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "center",
        class: "acciones-width",
      },
      { text: "Patente", filterable: true, value: "patente" },
      { text: "Año", filterable: true, value: "anio" },
      { text: "Marca", filterable: true, value: "marca" },
      { text: "Modelo", filterable: true, value: "modelo" },
      { text: "Tipo", filterable: true, value: "tipo_vehiculo" },
      { text: "Combustible", filterable: true, value: "combustible" },
      /*{ text: "Chasis", filterable: true, value: "chasis" },
						      { text: "Motor", filterable: true, value: "motor" },
						      { text: "Zona", filterable: true, value: "zona" },*/
      { text: "Departamento", filterable: true, value: "departamento" },
      /*{ text: "Version", filterable: true, value: "version" },      */
      { text: "Estado", filterable: true, value: "estado" },
    ],

    desserts: [],
    editedIndex: -1,
    editedItem: {
      tipo_vehiculo_id: {},
      combustible_id: {},
      marca_id: {},
      modelo_id: {},
      estado_id: {},
      departamento_id: {},
    },
    defaultItem: {
      tipo_vehiculo_id: {},
      combustible_id: {},
      marca_id: {},
      modelo_id: {},
      estado_id: {},
      departamento_id: {},
    },
  }),

  computed: {
    dateRangeText: {
      get: function () {
        return this.dates.join(" ~ ");
      },
      set: function (date) {
        return date;
      },
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : "Editar " + this.title;
    },
    computedDeclaracion() {
      return this.formatDate(this.editedItem.declaracion);
    },
  },

  watch: {
    dialog(val) {
      if (val == true) {
        this.componentKey++;
        this.componentKeyDetails++;
      }

      val || this.close();
    },
    pagination: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },

  created() {
    this.initialize();
    this.getTipos();
    this.getMarcas();
    //this.getZonas();
    this.getDeptos();
    this.getEstados();
    this.getCombustibles();
    //this.getVersiones();
  },

  methods: {
    getTipos() {
      this.$axiosApi
        .get("tipo_vehiculos")
        .then((response) => {
          this.tipos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener tipos. Error: " + response.message;
          this.color = "error";
        });
    },
    getCombustibles() {
      this.$axiosApi
        .get("combustibles")
        .then((response) => {
          this.combustibles = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener combustibles. Error: " + response.message;
          this.color = "error";
        });
    },
    getMarcas() {
      this.$axiosApi
        //.get("api/getMarcasActivas")
        .get("marcas")
        .then((response) => {
          this.marcas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener marcas. Error: " + response.message;
          this.color = "error";
        });
    },
    getZonas() {
      this.$axiosApi
        .get("zonas")
        .then((response) => {
          this.zonas = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener zonas. Error: " + response.message;
          this.color = "error";
        });
    },
    getDeptos() {
      this.$axiosApi
        .get("departamentos")
        .then((response) => {
          this.deptos = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text =
            "Error al obtener departamentos. Error: " + response.message;
          this.color = "error";
        });
    },
    getEstados() {
      this.$axiosApi
        .get("estados")
        .then((response) => {
          this.estados = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener estados. Error: " + response.message;
          this.color = "error";
        });
    },
    getVersiones() {
      this.$axiosApi
        .get("versiones")
        .then((response) => {
          this.versiones = response.data;
        })
        .catch((response) => {
          this.snackbar = true;
          this.text = "Error al obtener versiones. Error: " + response.message;
          this.color = "error";
        });
    },
    getDate() {
      return Math.floor(Date.now() / 1000);
    },
    async fetchData() {
      let query = this.formSearch;
      let formData = new FormData();
      formData = this.getQryParams(query);
      let queryParams = new URLSearchParams(formData).toString();
      let url = this.urlExport;
      const response = await this.$axiosApi.getByQuery(url, queryParams);
      return response.data.data;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    editItem(item) {
      let id = item.id;

      Swal.fire({
        title: "Aguarde un instante!",
        icon: "info",
        html:
          "Buscando datos del vehiculo:" +
          item.marca +
          " " +
          item.modelo +
          " (" +
          item.patente +
          ")",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      this.$axiosApi
        .getById(this.route, id)
        .then((r) => {
          Swal.close();
          this.componentKeyDetails++;
          this.editedIndex = r.data.id;
          delete r.data.id;
          this.editedItem = Object.assign({}, r.data.vehiculo);
          this.editedItem.marca_id = r.data.vehiculo.marca_id
            ? {
                id: Number(r.data.vehiculo.marca_id),
                nombre: r.data.vehiculo.marca,
                logo_url: r.data.vehiculo.logo_url,
              }
            : null;
          this.editedItem.modelo_id = r.data.vehiculo.modelo_id
            ? {
                id: Number(r.data.vehiculo.modelo_id),
                nombre: r.data.vehiculo.modelo,
                foto: r.data.vehiculo.modelo_foto,
              }
            : null;
          this.editedItem.tipo_vehiculo_id = r.data.vehiculo.tipo_vehiculo_id
            ? {
                id: Number(r.data.vehiculo.tipo_vehiculo_id),
                nombre: r.data.vehiculo.tipo_vehiculo,
              }
            : null;
          this.editedItem.combustible_id = r.data.vehiculo.combustible_id
            ? {
                id: Number(r.data.vehiculo.combustible_id),
                nombre: r.data.vehiculo.combustible,
              }
            : null;
          this.editedItem.estado_id = r.data.vehiculo.estado_id
            ? {
                id: Number(r.data.vehiculo.estado_id),
                nombre: r.data.vehiculo.estado,
              }
            : null;
          this.editedItem.departamento_id = r.data.vehiculo.departamento_id
            ? {
                id: Number(r.data.vehiculo.departamento_id),
                nombre: r.data.vehiculo.departamento,
              }
            : null;
          this.dialogDetails = true;
        })
        .catch(function (error) {
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";
        });
    },

    /*deleteItem(id) {
      this.$confirm({
        message: `Eliminar Registro?`,
        button: {
          no: "Cancelar",
          yes: "Confirmar",
        },

        callback: (confirm) => {
          if (confirm) {
            axios.delete(this.baseUrl + "/" + id).then(
              (response) => {
                this.snackbar = true;
                this.text = "Registro Eliminado";
                this.initialize();
              },
              (error) => {
                this.snackbar = true;
                this.text =
                  "Error al eliminar el departamento Error: " + e.message;
                this.color = "error";
              }
            );
          }
        },
      });
    },*/
    reset() {
      this.dates = [];
      this.$refs.formBusqueda.reset();
      this.initialize();
    },
    getQryParams(dataSearch) {
      let formData = new FormData();
      if (dataSearch.tipo_vehiculo_id) {
        formData.append("tipo_vehiculo_id", dataSearch.tipo_vehiculo_id);
      }
      if (dataSearch.combustible_id) {
        formData.append("combustible_id", dataSearch.combustible_id);
      }
      if (dataSearch.marca_id) {
        formData.append("marca_id", dataSearch.marca_id);
      }
      if (dataSearch.zona_id) {
        formData.append("zona_id", dataSearch.zona_id);
      }
      if (dataSearch.depto_id) {
        formData.append("depto_id", dataSearch.depto_id);
      }
      if (dataSearch.estado_id) {
        formData.append("estado_id", dataSearch.estado_id);
      }
      if (dataSearch.version_id) {
        formData.append("version_id", dataSearch.version_id);
      }
      if (dataSearch.numero) {
        formData.append("numero", dataSearch.numero);
      }
      if (dataSearch.anio) {
        formData.append("anio", dataSearch.anio);
      }
      if (dataSearch.chasis) {
        formData.append("chasis", dataSearch.chasis);
      }
      if (dataSearch.patente) {
        formData.append("patente", dataSearch.patente);
      }
      if (this.dates.length > 0) {
        formData.append("created_at_start", this.dates[0]);
        formData.append("created_at_end", this.dates[1]);
      }

      return formData;
    },
    initialize() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;
      let url = this.route;

      if (this.formSearch && !sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);
        let query = new URLSearchParams(formData).toString();
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (this.formSearch && sortBy) {
        let formData = new FormData();
        formData = this.getQryParams(this.formSearch);

        let query = new URLSearchParams(formData).toString();
        let direction = "desc";
        //sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        query =
          query +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage +
          "&sortBy=" +
          this.pagination.sortBy +
          "&direction=" +
          direction;

        this.$axiosApi
          .getByQuery(url, query)
          /*
        axios
          .get(
            url +
              "?" +
              query +
              "&page=" +
              this.pagination.page +
              "&per_page=" +
              this.pagination.itemsPerPage +
              "&sortBy=" +
              this.pagination.sortBy +
              "&direction=" +
              direction
          )
          */
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));

        return;
      }

      if (sortBy && !this.formSearch) {
        let direction =
          sortDesc[0] == true || sortDesc[0] == undefined ? "desc" : "asc";
        let query =
          "?direction=" +
          direction +
          "&sortBy=" +
          this.pagination.sortBy +
          "&page=" +
          this.pagination.page +
          "&per_page=" +
          this.pagination.itemsPerPage;

        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }

      if (!this.formSearch && !sortBy) {
        //console.log('no hay parametros');
        this.$axiosApi
          .getByQuery(url, query)
          .then((r) => {
            //console.log(r.data);
            this.desserts = r.data.data;
            this.pagination.current = r.data.current_page;
            this.total = r.data.total;
            this.loading = false;
          })
          .catch(function (error) {
            this.loading = false;
            this.snackbar = true;
            this.text = "Error al obtener conductores. Error: " + error;
            this.color = "error";
          })
          .finally(() => (this.loading = false));
      }
    },

    close() {
      this.dialog = false;
      this.dialogDetails = false;
      //this.valid = true;
      //this.resetValidation();
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.initialize();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    saveFoto(id) {
      var fd = new FormData();
      var file = this.editedItem.foto;
      fd.append("vehiculo", id);
      let i = 0;
      if (file) {
        fd.append("file[" + i + "]", file, file.name);
        i++;
      }

      this.$axiosApi
        .postFile("vehiculos_upload", fd)
        /*axios
        .post("/api/vehiculos-upload", fd, {
          headers: { "Content-Type": "multipart/form-data" },
        })*/
        .then((r) => {
          //subida exitosa
          console.log("upload ok");
          return true;
        })
        .catch((e) => {
          this.snackbar = true;
          this.text = "Error al cargar archivos. Error: " + e.message;
          this.color = "error";
        });
    },

    save() {
      //console.log(this.$refs.vehiculoForm.form);

      if (this.$refs.vehiculoForm.validar()) {
        this.editedItem = this.$refs.vehiculoForm.form;

        /*let method = "post";
        let url = this.baseUrl;
        axios({
          method,
          url,
          data: this.editedItem,
        })*/
        this.$axiosApi
          .post(this.route, this.editedItem)
          .then((r) => {
            if (typeof r !== "undefined" && r !== null) {
              //this.saveFoto(r.data.vehiculo);
              this.snackbar = true;
              this.text = "Registro Creado";
              this.color = "success";
              this.close();
            } else {
              this.snackbar = true;
              this.text = "Se ha producido un error al registrar el vehiculo";
              this.color = "error";
            }

            this.initialize();
          })
          .catch((e) => {
            console.log(e);
            this.snackbar = true;
            this.text = "Error al ingresar los datos. Error: " + e.message;
            this.color = "error";
          });

        //this.close();
      }
    },
  },
  mounted() {
    console.log("Componente " + title() + " creado");
  },
};
</script>
